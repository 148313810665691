<template>
  <div class="dropdown user-menu" data-bs-auto-close="outside">
    <button type="button" class="btn btn-inline dropdown-toggle" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      <span class="badge test-user" v-if="userRef?.isTestUser">
        TEST
      </span>
      <span class="badge admin-user" v-if="TokenManager.isPrivilegedUser">
        ADMIN
      </span>
      <img src="@/assets/icons/person_gray_150.png" class="user-icon"/>
      <span class="user-name">
        {{ userRef?.displayName }}
      </span>
    </button>
    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
      <li class="hide-md">
        <div class="dropdown-item disabled user-name">
          <img src="@/assets/icons/person_gray_150.png" class="user-icon"/>
          {{ userRef?.displayName }}
        </div>
      </li>
      <li class="hide-md"><hr class="dropdown-divider"></li>
      <li>
        <router-link class="dropdown-item" to="/account">
          Account
        </router-link>
      </li>
      <li>
        <router-link class="dropdown-item" to="/profile">
          Profile
        </router-link>
      </li>
      <li><hr class="dropdown-divider"></li>
      <li>
        <router-link class="dropdown-item" to="/trees">Trees</router-link>
      </li>
      <li>
        <router-link class="dropdown-item" :to="{ name: 'account.familyGroups' }">
          Groups
          <span v-if="notificationCountRef > 0" class="badge notify-badge rounded-pill">
            {{ notificationCountRef }}
          </span>
        </router-link>
      </li>
      <li v-if="!TokenManager.isSupervised">
        <router-link class="dropdown-item" to="/profiles">
          Managed profiles
        </router-link>
      </li>
      <li v-if="supervisedUsersRef.length > 0"><hr class="dropdown-divider"></li>
      <li v-if="supervisedUsersRef.length > 0" class="dropdown-header">
        Supervised users
      </li>
      <li v-for="su in supervisedUsersRef" :key="su.id" class="">
        <router-link class="dropdown-item" :to="User.getPageUrl(su.id)">
          {{ su.displayName }}
        </router-link>
      </li>
      <li v-if="TokenManager.isPrivilegedUser"><hr class="dropdown-divider"></li>
      <li v-if="TokenManager.hasPrivilege(UserPrivilege.ReadAllUsers)">
        <router-link class="dropdown-item admin" to="/admin/users">All Users</router-link>
      </li>
      <li><hr class="dropdown-divider"></li>
      <li v-if="showRefreshTokenRef">
        <button type="button" class="dropdown-item dev-only" @click="onRefreshToken">
          Refresh access token
        </button>
      </li>
      <li><router-link class="dropdown-item" to="/signout">Sign out</router-link></li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.dropdown-toggle {
  display: flex;
  gap: 0.25rem;
  align-items: center;

  .user-name {
    display: none;
  }

  @media (min-width: 992px) {
    .user-name {
      display: inline;
      color: rgba(0, 0, 0, 0.8);
    }

    .badge {
      order: 1;
    }
  }
}

img.user-icon {
  height: 30px;
  border-radius: 50%;
  background-color: #ddd;
  padding: 4px;
  opacity: 60%;
}

.badge {
  &.test-user {
    margin-left: 0.25em;
    background-color: var(--bs-warning);
    color: #888;
  }

  &.admin-user {
    background-color: var(--bs-danger);
    color: white;
  }
}

.dropdown-toggle .notify-badge {
  top: 4px;
  right: -12px;
}

.dropdown-menu {
  min-width: 250px;
}

.dropdown-item {
  &.admin {
    color: var(--bs-warning);
  }
}

#userDropdown::after {
  display: none;
}
</style>

<script lang="ts" setup>
import { computed } from 'vue'
import { useUserStore } from '@/gp/UserStore'
import { useUserSupervisorStore } from '@/gp/UserSupervisorStore'
import { useFamilyGroupInviteStore } from '@/gp/FamilyGroupInviteStore'
import { LoadMode } from '@/util/AsyncData'
import { TokenManager, UserPrivilege } from '@/auth/TokenManager'
import { User } from '@/gp/GroupAdminModel'
import { Environment } from '@/util/Environment'
import { InvitationResult } from '@/gp/GroupAdminModel'

const userStore = useUserStore()
const userSupervisorStore = useUserSupervisorStore()
const inviteStore = useFamilyGroupInviteStore()

const userRef = computed(() => userStore.getAsyncSelf(LoadMode.EnsureLoaded)?.data)
const supervisedUsersRef = computed(() => {
  if (TokenManager.isSupervised)
    return [] // can't supervise others, and API call is forbidden anyway
  const sups = userSupervisorStore.getForSupervisor(TokenManager.userId, LoadMode.EnsureLoaded)
  return userStore.getUserList(sups.map(s => s.forUserId!))
})

const showRefreshTokenRef = computed(() => Environment.isDevelopment())

const pendingInvitesRef = computed(() => inviteStore.getInvitesForUser(undefined, LoadMode.EnsureLoaded)
  .filter(inv => inv.result == InvitationResult.Pending))

const notificationCountRef = computed(() => pendingInvitesRef.value.length)

async function onRefreshToken() {
  await TokenManager.refreshAsync()
}
</script>