<template>
  <form class="site-search">
    <div class="dropdown">
      <input class="form-control" type="search" ref="searchInputRef" v-model="searchTextRef" placeholder="Search for people" aria-label="Search"/>
      <ul class="dropdown-menu dropdown-menu-start">
        <li v-for="r in searchResultsRef" :key="r.id" class="dropdown-item">
          <PersonLink :personId="r.id" class="link-dark" @click="searchResultSelected"></PersonLink>
        </li>
      </ul>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.site-search {
  width: 100%;

  @media (min-width: 768px) {
    width: 100px;
    transition: width 0.3s ease;

    &:focus-within {
      width: 300px;
    }
  }

  .dropdown {
    //width: 40%;

    .dropdown-toggle::after {
      display: none;
      position: relative;
    }
  }

  input {
    border-color: #ccc;
    border-radius: 20px;
    height: 2em;
    padding-left: 2.1em;
    background-image: url('@/assets/icons/search_gray_60.png');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 8px 5px;

    &::placeholder {
      color: #aaa;
    }

    @media (min-width: 768px) {
      &:not(:focus)::placeholder {
        color: transparent;
      }
    }
  }

  .dropdown-menu {
    max-width: 450px;
  }
}
</style>

<script lang="ts" setup>
import { ref, watchEffect } from 'vue'
import { Dropdown } from 'bootstrap'
import { useLivePersonSearch } from '@/rd/LivePersonSearch'
import PersonLink from '@/manage/PersonLink.vue'

const searchInputRef = ref<HTMLInputElement>()
const { searchTextRef, searchResultsRef } = useLivePersonSearch()

watchEffect(() => {
  var text = searchTextRef.value // access before 'if' so watch will notice
  if (searchInputRef.value) {
    const dd = new Dropdown(searchInputRef.value)
    if (text.length > 2) {
      dd.show()
    }
    else {
      dd.hide()
    }
  }
})

function searchResultSelected() {
  searchTextRef.value = ''
  searchResultsRef.value = []  
}
</script>
