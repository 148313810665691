import { defineStore } from 'pinia'
import { DataGroup, ViewDataGroup, DataGroupType, GroupMemberRole, ItemPermissions } from './GroupAdminModel'
import { gp } from './GroupAdminApi'
import { AsyncData, LoadMode } from '@webapp/util/AsyncData'
import { AsyncDataStore, VersionedKeyList } from '@webapp/util/AsyncDataStore'
import { useUserStore } from './UserStore'
import { useDataGroupMemberStore } from './DataGroupMemberStore'
import { getAsyncPermissionsCore, getAsyncPermissionsListCore, listToItemPermissions } from './Permissions'
import { TokenManager } from '@webapp/auth/TokenManager'
import { DateTime } from 'luxon'
import { DateTimeUtil } from '@webapp/util/LuxonUtil'
import { assignExisting, isDefined } from '@webapp/util/TypeScriptUtil'
import { PaginatedList, PatchChange } from '@webapp/util/Api'
import { reactive } from 'vue'
import { useWorkspaceStore } from '@webapp/rd/WorkspaceStore'


export const useDataGroupStore = defineStore('dataGroups', () => {
    const asyncDataGroups = reactive(new Map<string, AsyncData<DataGroup>>())
    const asyncDataGroupKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())
    const asyncMemberKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())
    const asyncInviteKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())
    const viewGroups = reactive(new Map<string, ViewDataGroup>())
    const asyncPermissions = reactive(new Map<string, AsyncData<ItemPermissions>>())

    const dataGroupMemberStore = useDataGroupMemberStore()
    const userStore = useUserStore()

    function getAsyncGroup(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        return id ? getAsyncGroupList([id], loadMode)[0] : undefined
    }

    function getAsyncGroupList(ids: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const loadAsync = async (ids: string[], requestTime: DateTime) => {
            const plainItems = await gp.getPlainByIdsAsync("datagroups", ids, "owner")
            console.log(`Loaded ${plainItems.length} data groups by id: ${plainItems.map(p => p.id).join(", ")}`)
            return processExpandedDataGroups(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncItemsByIds(asyncDataGroups, ids, "DataGroup", loadMode, loadAsync)
    }

    async function getGroupListAsync(ids: (string | undefined)[]) {
        return (await Promise.all(getAsyncGroupList(ids, LoadMode.EnsureLoaded).map(a => a.whenLoadCompleted))).filter(isDefined)
    }

    function getLoadedGroupList(ids: (string | undefined)[]) {
        return ids.map(id => asyncDataGroups.get(id ?? '')).map(a => a?.data).filter(isDefined)
    }

    function getAsyncKeyListForAll(loadMode = LoadMode.TrackOnly) {
        const query = { pageSize: 1000, expand: "items/owner" } // max
        return AsyncDataStore.getAsyncKeyListForRelatedItems("all", "Root", asyncDataGroupKeys,
            asyncDataGroups, "DataGroup", loadMode, (k, t) => getPaginatedListAsync(query, t))!
    }

    function getAllGroups(loadMode = LoadMode.TrackOnly) {
        return getAsyncGroupList(getAsyncKeyListForAll(loadMode)?.data?.keys ?? [])
            .map(a => a.data).filter(isDefined)
    }

    async function getOwnedResearchGroupsAsync() {
        const query = { pageSize: 1000, filter: "GroupType eq 'Research'", expand: "items/owner" } // max
        const asyncKeyList = AsyncDataStore.getAsyncKeyListForRelatedItems("research", "Root", asyncDataGroupKeys,
            asyncDataGroups, "DataGroup", LoadMode.Reload, (k, t) => getPaginatedListAsync(query, t))
        const keyList = await asyncKeyList.whenLoadCompleted
        const groups = getLoadedGroupList(keyList?.keys ?? [])
        return groups.filter(g => g.ownerId == TokenManager.userId)
    }

    async function getPaginatedListAsync(query: object, requestTime: DateTime) {
        const plainPage = await gp.getPlainAsync("datagroups", query) as PaginatedList
        if (plainPage.nextPageToken)
            throw 'Too many data groups requested'
        return processExpandedDataGroups(plainPage.items, requestTime)
    }

    async function getViewGroupsForUserAsync(userId?: string, reload = false) {
        userId ??= TokenManager.userId
        // only view groups for the current user are cached
        if (userId == TokenManager.userId && viewGroups.size > 0 && !reload) {
            return [...viewGroups.values()]
        }

        const plainGroups = await gp.getPlainCollectionAsync(`users/${userId}/viewDataGroups`)
        const groups = plainGroups.map(plainToViewDataGroup)
        if (userId == TokenManager.userId) {
            // update cache
            viewGroups.clear();
            groups.forEach(g => viewGroups.set(g.id!, g))
        }
        return groups;
    }

    function getAsyncPermissions(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncPermissionsCore(asyncPermissions, id, "DataGroup", "datagroups", loadMode)
    }

    function getLoadedPermissions(id: string | undefined) {
        return getAsyncPermissions(id)?.data ?? ItemPermissions.None
    }
    
    function getAsyncPermissionsList(ids: string[], loadMode = LoadMode.TrackOnly) {
        return getAsyncPermissionsListCore(asyncPermissions, ids, "DataGroup", "datagroups", loadMode)
    }

    async function getPermissionsListAsync(ids: string[]) {
        return Promise.all(getAsyncPermissionsList(ids, LoadMode.EnsureLoaded).map(a => a.whenLoadCompleted))
    }
    
    async function addAsync(dataGroup: DataGroup) {
        const addCoreAsync = async () => {
            const p = await gp.postPlainAsync(`datagroups`, dataGroup)
            return plainToDataGroup(p, TokenManager.userId) // current user is owner on add
        }            
        return await AsyncDataStore.addToStoreMapAsync(asyncDataGroups, "DataGroup", dg => dg.id, addCoreAsync)
    }

    async function patchAsync(id: string, changes: PatchChange[]) {
        const patchCoreAsync = async (id: string, changes: PatchChange[]) => {
            const p = await gp.patchPlainAsync(`datagroups/${id}`, changes)
            return plainToDataGroup(p)
        }
        await AsyncDataStore.patchFromStoreAsync(asyncDataGroups, id, changes, patchCoreAsync)
        getAsyncGroup(id, LoadMode.Reload) // trigger reload, but don't wait for it
    }

    async function unlinkAsync(id: string) {
        await gp.postPlainAsync(`datagroups/${id}/unlink`)
        await getAsyncGroup(id, LoadMode.Reload)?.whenLoadCompleted
    }

    async function deleteAsync(id: string) {
        const dg = asyncDataGroups.get(id)?.data
        await gp.deleteAsync(`datagroups/${id}`)
        AsyncData.setDeleted(asyncDataGroups.get(id))
        AsyncData.setDeleted(asyncMemberKeys.get(id))
        AsyncData.invalidate(userStore.asyncDataGroupMemberKeys.get(TokenManager.userId!))
        if (dg?.groupType == DataGroupType.Research) {
            const workspaceStore = useWorkspaceStore()
            AsyncData.invalidate(workspaceStore.asyncWorkspaceKeys.get('all'))
        }
        // TODO: clear other stores of cached data belonging to this group?
    }

    function addDataGroupsToStore(plainDataGroups: any[], requestTime: DateTime) {
        const dataGroupMap = processExpandedDataGroups(plainDataGroups, requestTime)
        const dataGroupStore = useDataGroupStore()
        AsyncDataStore.addItemMapToStore(dataGroupStore.asyncDataGroups, "DataGroup", dataGroupMap, requestTime)
    }

    function plainToDataGroup(p: any, ownerId?: string) {
        const group = assignExisting(new DataGroup(), p)
        group.groupType = p.groupType ?? DataGroupType.System // default
        group.createdDate = DateTimeUtil.fromAPI(p.createdDate)
        group.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate)
        group.ownerId = ownerId ?? p.owner?.id

        if (!group.ownerId) {
            throw `Data group ${group.id} loaded without owner`
        }
        return group
    }

    function processExpandedDataGroups(plainItems: any[], requestTime: DateTime) {
        userStore.addUsersToStore(plainItems.map(dg => dg.owner).filter(isDefined), requestTime)
        return AsyncDataStore.createItemMap(plainItems, "DataGroup", plainToDataGroup, dg => dg.id!)
    }

    function plainToViewDataGroup(p: any) {
        const group = assignExisting(new ViewDataGroup(), p)
        group.groupType = p.groupType ?? DataGroupType.System // default
        group.permissions = listToItemPermissions(p.permissions)
        return group
    }
    return {
        asyncDataGroups,
        asyncMemberKeys,
        asyncInviteKeys,
        viewGroups,
        asyncPermissions,
        getAsyncGroup,
        getAsyncGroupList,
        getGroupListAsync,
        getLoadedGroupList,
        getAsyncKeyListForAll,
        getAllGroups,
        getOwnedResearchGroupsAsync,
        getViewGroupsForUserAsync,
        getAsyncPermissions,
        getLoadedPermissions,
        getAsyncPermissionsList,
        getPermissionsListAsync,
        addAsync,
        patchAsync,
        unlinkAsync,
        deleteAsync,
        addDataGroupsToStore,
    }
})
