<template>
  <div ref="modalElemRef" class="modal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" :class="{ 'modal-sm': props.small }">
      <form class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            <slot name="icon"></slot>
            <slot v-if="$slots.title" name="title"></slot>
            <span v-else>{{ title }}</span>
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" :disabled="busyRef"></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer" v-if="!props.hideActions">
          <button type="submit" class="btn me-2" :class="okStyleClassRef" :disabled="!canSubmitRef" @click="onOk">
            <Spinner v-if="busyRef"></Spinner>
            <slot v-else name="okIcon"></slot>
            <span class="ok-text">
              <template v-if="busyRef && ($slots.busyText || props.busyText)">
                <slot v-if="$slots.busyText" name="busyText"></slot>
                <span v-else>{{ props.busyText }}</span>
              </template>
              <template v-else>
                <slot v-if="$slots.okText" name="okText"></slot>
                <span v-else>{{ okStateTextRef }}</span>
              </template>
            </span>
          </button>
          <button type="button" v-if="!props.info" class="btn btn-outline-primary" data-bs-dismiss="modal" :disabled="busyRef">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-body {
  padding-top: 0;
}

.modal-footer {
  button[type="submit"] {
    min-width: 80px;

    .ok-text {
      margin-left: 0.25rem;
    }
  }
}
</style>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { Modal as BsModal } from 'bootstrap'
import Spinner from '@webapp/util/Spinner.vue'

type OkStyles = ("warning" | "danger")

const props = defineProps<{
  title?: string,
  okText?: string,
  okStyle?: OkStyles,
  okDisabled?: boolean,
  busyText?: string,
  info?: boolean,
  hideActions?: boolean,
  small?: boolean,
}>()
const emit = defineEmits([
  'ok'
])
defineExpose({
  open,
  ok,
  close 
})

const modalElemRef = ref<InstanceType<typeof Element>>()

const busyRef = ref(false)
const okStyleClassRef = computed(() => 
  busyRef.value ? 'btn-secondary' : 
  props.okStyle ? `btn-${props.okStyle}` : 
  'btn-primary'
)
const okStateTextRef = computed(() => busyRef.value && props.busyText ? props.busyText : (props.okText ?? 'OK'))
const canSubmitRef = computed(() => !props.okDisabled && !busyRef.value)

onMounted(() => document.addEventListener('keydown', onFormKey))
onUnmounted(() => document.removeEventListener('keydown', onFormKey))

function onFormKey(e: KeyboardEvent) {
  if (e.key === 'Escape') {
    close()
  }
}

function onOk(e: Event) {
  e.preventDefault() // don't submit the page as a form

  if (props.info) {
    close()
  }
  else {
    ok()
  }
}

function ok(result?: string) {
  busyRef.value = true
  emit('ok', result)
}

function open() {
  // HACK: Not sure why getInstance isn't sufficient here, but sometimes it returns null because apparently the BS Modal
  // hasn't been initialized yet. Calling getOrCreateInstance seems to fix it.
  BsModal.getOrCreateInstance(modalElemRef.value!).show()
  busyRef.value = false
}

function close() {
  BsModal.getInstance(modalElemRef.value!)?.hide()
  busyRef.value = false
}
</script>
